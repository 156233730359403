// Insert multiple items at a given index
export function insert({ array, index, items }) {
  return [...array.slice(0, index), ...items, ...array.slice(index)];
}

// Initialize an array with a given value
export function initialize({ size, value }) {
  const arr = [];

  for (let i = 0; i < size; i++) {
    arr.push(value);
  }

  return arr;
}
