import Access from "@/constants/access";
import ServiceType from "@zubut/common/src/constants/services/type";
import Vue from "vue";
import DropdownOption from "@zubut/common/src/models/dropdown-option";

/**
 * Get the list of service options the user has access to
 * @returns {Array<{text: string, value: number}>}
 */
export default function getUserServiceOptions(): DropdownOption[] {
  const permissionsOption = new Map([
    [
      Access.deliveryCenter.READ_EXPRESS_DELIVERIES,
      ServiceType.NUM_MULTI_POINT
    ],
    [Access.deliveryCenter.READ_RESERVATIONS, ServiceType.NUM_RENT_PER_HOUR],
    [
      Access.deliveryCenter.READ_SAME_NEXT_DAY_DELIVERIES,
      ServiceType.NUM_PARCEL
    ]
  ]);
  const restrictedServiceTypes: number[] = [];
  for (const [permission, serviceType] of permissionsOption) {
    const hasPermission = Vue.prototype.$ability.can(
      permission,
      Access.deliveryCenter.moduleName
    );
    if (!hasPermission) {
      restrictedServiceTypes.push(serviceType);
    }
  }

  const serviceTypeOptions = ServiceType.options.filter(
    (option: DropdownOption) =>
      option.value != null &&
      !restrictedServiceTypes.includes(<number>option.value)
  );

  return [{ text: "Todos", value: null }, ...serviceTypeOptions];
}
