










































































































































import Vue, { VueConstructor } from "vue";
import Deliveries from "@/services/deliveries";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination.vue";
import StatusDestination from "@zubut/common/src/components/StatusDestination.vue";
import StatusParcel from "@zubut/common/src/components/StatusParcel.vue";
import ServiceTypeBadge from "@zubut/common/src/components/ServiceTypeBadge.vue";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown.vue";
import ZVehicle from "@zubut/common/src/components/ZVehicle.vue";
import ZDropdown from "@zubut/common/src/components/ZDropdown.vue";
import ZDropdownCities from "@/app/components/ZDropdownCities.vue";
import ServiceType from "@zubut/common/src/constants/services/type";
import VehicleType from "@zubut/common/src/constants/vehicles/type";
import DeliveryStatusConstants from "@zubut/common/src/constants/destinations/status";
import ParcelStatusConstants from "@zubut/common/src/constants/parcels/status";
import { initialize } from "@/utils/array";
import { formatMoney } from "@/utils/money";
import { makeShortUUID } from "@/utils/strings";
import NotifyMixin from "@/mixins/notify.vue";
import PaginationMixin from "@/mixins/pagination.vue";
import { formatISO } from "@zubut/common/src/utils/time";
import UserServiceTypeOptions from "@/utils/user-service-type-options";
import HistoryDelivery from "@zubut/common/src/models/deliveries/history-delivery";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import DropdownOption from "@zubut/common/src/models/dropdown-option";
import * as mutation from "@/store/modules/cities/mutations-types";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof NotifyMixin> & InstanceType<typeof PaginationMixin>
>).extend({
  name: "ClientDetailServices",

  components: {
    ServiceTypeBadge,
    StatusDestination,
    StatusParcel,
    ZDropdown,
    ZDropdownCities,
    ZTable,
    ZTableHeaderDropdown,
    ZTablePagination,
    ZVehicle
  },

  mixins: [NotifyMixin, PaginationMixin],

  data() {
    return {
      loading: true,
      services: [] as HistoryDelivery[],
      sortBy: "createdAt",
      sortOrder: "desc",
      zoneBoundaries: false,
      status: null,
      type: null,
      filterVehicleType: null,
      vehicleTypeOptions: [
        { text: "Todos", value: null } as DropdownOption
      ].concat(VehicleType.options),
      statusOptions: [{ text: "Todos", value: null } as DropdownOption].concat(
        DeliveryStatusConstants.optionsHistory
      ),
      fields: [
        {
          key: "deliveryType",
          label: ""
        },
        {
          key: "id",
          label: "ID Entrega",
          formatter: (value: string) => makeShortUUID(value)
        },
        {
          key: "createdAt",
          label: "Fecha",
          formatter: (value: string) =>
            formatISO(value, "PPPp").replace("a las ", "")
        },
        { key: "clientName", label: "Cliente" },
        {
          key: "vehicleType"
        },
        {
          key: "subtotal",
          label: "Subtotal",
          formatter: (value: number) => formatMoney(value)
        },
        {
          key: "tax",
          label: "IVA",
          formatter: (value: number) => formatMoney(value)
        },
        {
          key: "total",
          label: "Total",
          formatter: (value: number) => formatMoney(value)
        },
        {
          key: "status",
          label: "Estatus"
        }
      ],
      placeholderItem: {
        id: "",
        createdAt: null,
        driver: "",
        cost: 0,
        status: 0,
        paymentStatus: 0,
        billingStatus: 0
      },
      hasError: false,
      where: {
        clientId: this.$route.params.id,
        cityId: this.$store.getters["cities/getCity"]
      } as any
    };
  },

  computed: {
    serviceTypeOptions(): DropdownOption[] {
      return UserServiceTypeOptions();
    },

    selectedCity: {
      get(): string | null {
        return this.$store.getters["cities/getCity"];
      },
      set(val: string | null) {
        this.$store.commit(`cities/${mutation.SET_SELECTED_CITY}`, val);
      }
    },

    parcelType() {
      return ServiceType.NUM_PARCEL_PICKUP;
    },

    truckType() {
      return VehicleType.NUM_TRUCK;
    }
  },

  watch: {
    currentPage: "getDeliveries",
    perPage: "getDeliveries",
    type: {
      handler() {
        this.servicesFilter();
      }
    },
    status: {
      handler() {
        this.statusFilter();
      }
    },
    filterVehicleType: {
      handler() {
        this.vehicleFilter();
      }
    },
    selectedCity: {
      handler() {
        this.cityFilter();
      }
    }
  },

  beforeMount() {
    this.getDeliveries();
  },

  methods: {
    getDeliveries() {
      this.loading = true;
      if (this.where.cityId === null) {
        this.cityFilter();
      }
      Deliveries.history({
        filter: this.filter,
        where: this.where
      })
        .then(({ data: services, meta }: any) => {
          if (meta.skip === 0) {
            this.totalRows = meta.count;
            this.services = initialize({
              value: this.placeholderItem,
              size: this.totalRows
            });
          }
          this.services.splice(meta.skip, services.length, ...services);
        })
        .catch(err => {
          this.$captureError(err);
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleRowClick(item: HistoryDelivery) {
      this.$router.push({
        name: item.serviceStatus != null ? "serviceDetail" : "parcelDetail",
        params: { id: item.zubutId }
      });
    },

    chageSort() {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.filter.order = "createdAt " + this.sortOrder;
      this.getDeliveries();
    },

    servicesFilter() {
      if (this.type != null) {
        if (ServiceType.isMultiPoint(this.type ?? 0)) {
          const { NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC } = ServiceType;
          this.where = {
            ...this.where,
            deliveryType: { inq: [NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC] }
          };
        } else if (ServiceType.isParcel(this.type ?? 0)) {
          this.where = {
            ...this.where,
            deliveryType: { inq: ServiceType.parcelTypes }
          };
        } else {
          this.where = { ...this.where, deliveryType: this.type };
        }
      } else {
        delete this.where.deliveryType;
      }

      this.getDeliveries();
    },

    statusFilter() {
      if (this.status != null) {
        this.where = { ...this.where, status: this.status };
      } else {
        delete this.where.status;
      }
      this.getDeliveries();
    },

    vehicleFilter() {
      if (this.filterVehicleType != null) {
        this.where = { ...this.where, vehicleType: this.filterVehicleType };
      } else {
        delete this.where.vehicleType;
      }
      this.getDeliveries();
    },

    cityFilter() {
      if (this.selectedCity != null) {
        this.where.cityId = this.selectedCity;
      } else {
        delete this.where.cityId;
      }
      this.getDeliveries();
    },

    showParcelStatus(item: HistoryDelivery) {
      return (
        item.serviceStatus === null ||
        item.parcelStatus === ParcelStatusConstants.NUM_RETURNED_TO_ORIGIN ||
        item.parcelStatus === ParcelStatusConstants.NUM_LOST ||
        item.parcelStatus === ParcelStatusConstants.NUM_CANCELLED
      );
    }
  }
});
