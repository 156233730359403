
import Vue from "vue";

interface NotifyOptions {
  title: string;
  text: string;
  type: string;
}

export default Vue.extend({
  methods: {
    notify({ title, text, type }: NotifyOptions) {
      this.$notify({
        group: "notifications",
        title,
        text,
        type,
        duration: 5000
      });
    }
  }
});
